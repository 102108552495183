import {Address} from '../../../core/address';
import {Race} from '../../../admin-config/races/race/race';
import {HigherEducationInstitution} from '../../../higher-education-institutions/higher-education-institution/higher-education-institution';
import { BankAccountDetails } from './bank.account.details';

export class User {
  accountStatus: string;
  address: Address;
  alive: boolean;
  cellphone: string;
  createDate: Date;
  disability: boolean;
  cellphoneVerified: boolean;
  emailerified: boolean;
  email: string;
  firstName: string;
  id: string;
  idNumber: string;
  income: number;
  lastLoginDate: Date;
  gender: string;
  lastName: string;
  loginRetries: number;
  profileStatus: string;
  pushNotificationToken: string;
  password: string;
  role: string;
  roles: Array<string>;
  type: string;
  race: Race;
  allocated: number;
  workerStatus: string;
  manager: User;
  studentType: string;
  institution: HigherEducationInstitution;
  appealWorkerStudentType: string;
  casesWorkedOn: number;
  assignedCases: number;
  bankAccountDetails: BankAccountDetails
}
