<div class="row justify-content-center">
  <div class="col-md-3" >
    <div class="tracker-container justify-content-center p-3 my-4" >
      Status: {{getStatus(application)}}
<!--      <ng-stepper #cdkStepper [linear]="true" class="mt-4" [selectedIndex]="getIndex()" >-->

<!--        <cdk-step [editable]="false"  [stepControl]="" [completed]="getIndex() >0" >-->
<!--          <ng-template cdkStepLabel >-->
<!--            <div class="step-bullet">1</div>-->
<!--            <div class="step-title" >Application Submitted</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Father"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >1">-->
<!--          <ng-template cdkStepLabel >-->
<!--            <div class="step-bullet" >2</div>-->
<!--            <div class="step-title" >Awaiting Evaluation</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Gaurdian"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >2" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">3</div>-->
<!--            <div class="step-title" >Assessing Financial Eligibility</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-docs></app-apply-docs> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >3" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet" >4</div>-->
<!--            <div class="step-title" >Awaiting Academic Eligibility</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Mother"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >4" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet" >5</div>-->
<!--            <div class="step-title" >Awaiting Academic Results</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Mother"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >5" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet" >6</div>-->
<!--            <div class="step-title" >Provisionally Funded</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Mother"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" *ngIf="application.status==='Rejected'" [stepControl]="" [completed]="getIndex() >6" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet" >7</div>-->
<!--            <div class="step-title" >Rejected</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Spouse"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >7" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet" >{{application.status==='Rejected'?'8':'7'}}</div>-->
<!--            <div class="step-title" >Awaiting Documentation</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Gaurdian"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="getIndex() >8" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet" >{{application.status==='Rejected'?'9':'8'}}</div>-->
<!--            <div class="step-title" >Funded</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-docs></app-apply-docs> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--      </ng-stepper>-->
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-5">

    <div class="row my-5">
      <div class="col-md-1 text-center mt-2"> <i class="fa fa-user fa-2x"></i>
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">{{application?.user?.firstName}} {{application?.user?.lastName}}</p>

        <p class="mb-1 text-muted">
         {{application?.user.idNumber}}
        </p>
        <p class="mb-1 text-muted">
          {{application?.user.cellphone}}
        </p>
        <p class="mb-1 text-muted">
          Ref: {{application?.reference}}
        </p>


      </div>
    </div>

    <div class="row my-5" *ngIf="application.selectedHigherEducationInstitutionOne && application.selectedHigherEducationInstitutionTwo && application.selectedHigherEducationInstitutionThree">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Selected institution</p>
        <ul>
          <li *ngIf="application.selectedHigherEducationInstitutionOne">
            {{application.selectedHigherEducationInstitutionOne.name}}
          </li>
          <li *ngIf="application.selectedHigherEducationInstitutionTwo">
            {{application.selectedHigherEducationInstitutionTwo.name}}
          </li>
          <li *ngIf="application.selectedHigherEducationInstitutionThree">
            {{application.selectedHigherEducationInstitutionThree.name}}
          </li>
        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.courseOne && application.courseTwo && application.courseThree">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Selected field of study</p>
        <ul>
          <li *ngIf="application.courseOne">
            {{application.courseOne.name}}
          </li>
          <li *ngIf="application.courseTwo">
            {{application.courseTwo.name}}
          </li>
          <li *ngIf="application.courseThree">
            {{application.courseThree.name}}
          </li>
        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.address">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Demographic Information</p>
        <ul>
          <li *ngIf="application.address.line1">
            {{application.address.line1}}
          </li>
          <li *ngIf="application.address.line2">
            {{application.address.line2}}
          </li>
          <li *ngIf="application.address.suburb">
            {{application.address.suburb}}
          </li>
          <li *ngIf="application.address.provinceName">
            {{application.address.provinceName?.name}}
          </li>
          <li *ngIf="application.address.postalCode">
            {{application.address.postalCode}}
          </li>
        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.spouse">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Spouse Information</p>
        <ul>
          <li *ngIf="application.spouse.idNumber">
            {{application.spouse.idNumber}}
          </li>
          <li *ngIf="application.spouse.firstName">
            {{application.spouse.firstName}}
          </li>
          <li *ngIf="application.spouse.lastName">
            {{application.spouse.lastName}}
          </li>

        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.guardian">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Guardian Information</p>
        <ul>
          <li *ngIf="application.guardian.idNumber">
            {{application.guardian.idNumber}}
          </li>
          <li *ngIf="application.guardian.firstName">
            {{application.guardian.firstName}}
          </li>
          <li *ngIf="application.guardian.lastName">
            {{application.guardian.lastName}}
          </li>

        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.father">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Father Information</p>
        <ul>
          <li *ngIf="application.father.idNumber">
            {{application.father.idNumber}}
          </li>
          <li *ngIf="application.father.firstName">
            {{application.father.firstName}}
          </li>
          <li *ngIf="application.father.lastName">
            {{application.father.lastName}}
          </li>

        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.mother">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Mother Information</p>
        <ul>
          <li *ngIf="application.mother.idNumber">
            {{application.mother.idNumber}}
          </li>
          <li *ngIf="application.mother.firstName">
            {{application.mother.firstName}}
          </li>
          <li *ngIf="application.mother.lastName">
            {{application.mother.lastName}}
          </li>

        </ul>
      </div>
    </div>

    <div class="row my-5" *ngIf="application.disabilityAnnexure">
      <div class="col-md-1 text-center mt-2">
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">Disability Annexure</p>
        <ul>
          <li *ngIf="application.disabilityAnnexure">
            <a target="_blank" [href]="configService.getApi()+'file/'+application?.disabilityAnnexure.id">{{application?.disabilityAnnexure.name}}</a>

          </li>


        </ul>
      </div>
    </div>


    <div class="row my-5" *ngIf="(application?.rejectReason || application?.rejectReasons) && application.status !=='ProvisionallyFunded'">
      <div class="col-md-1 text-center mt-2"> <i class="fa fa-bell fa-2x"></i>
      </div>
      <div class="col-md-11">
<!--        <p class="notif-body mb-2">{{application?.rejectReason?.description}}</p>-->
        <p class="notif-body mb-2" *ngFor="let rejectReason of application?.rejectReasons">
          {{rejectReason.description}}
        </p>
      </div>
    </div>

    <div class="row my-5" *ngIf="application?.fundingCondition">
      <div class="col-md-1 text-center mt-2"> <i class="fa fa-bell fa-2x"></i>
      </div>
      <div class="col-md-11">
        <p class="notif-body mb-2">{{application?.fundingCondition}}</p>
      </div>
    </div>



    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="mb-5" *ngIf="application.disabilityAnnexure && application.disabilityAnnexure.id">
          <p class="mb-0"><strong>Disability Annexure</strong></p>
          <p class="mb-0">
            <a target="_blank" [href]="configService.getApi()+'file/'+application.disabilityAnnexure.id">{{application.disabilityAnnexure.name}}</a>
          </p>
        </div>

        <div class="mb-5" *ngIf="application.vulnerableChildOrNonSassDeclaration && application.vulnerableChildOrNonSassDeclaration.id">
          <p class="mb-0"><strong>{{application.vulnerableChildOrNonSassDeclaration.caption}}</strong></p>
          <p class="mb-0">
            <a target="_blank" [href]="configService.getApi()+'file/'+application.vulnerableChildOrNonSassDeclaration.id">{{application.vulnerableChildOrNonSassDeclaration.name}}</a>
          </p>
        </div>
        <div class="mb-5" *ngIf="application.spouse && application.spouse.idNumber">
          <p class="mb-0"><strong>Spouse</strong></p>
          <p class="mb-0">{{application.spouse.firstName}} {{application.spouse.lastName}}</p>
          <p class="mb-0">{{application.spouse.idNumber}}</p>
          <div *ngIf="application.spouse.supportingDocs">

              <p *ngFor="let document of application.spouse.supportingDocs">
                {{document.caption}}<br/>
                <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
              </p>
          </div>

        </div>
        <div class="mb-5" *ngIf="application.father && application.father.idNumber">
          <p class="mb-0"><strong>Father</strong></p>
          <p class="mb-0">{{application.father.firstName}} {{application.father.lastName}}</p>
          <p class="mb-0">{{application.father.idNumber}}</p>
          <div *ngIf="application.father.supportingDocs">
            <p *ngFor="let document of application.father.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>
        </div>
        <div class="mb-5" *ngIf="application.mother && application.mother.idNumber">
          <p class="mb-0"><strong>Mother</strong></p>
          <p class="mb-0">{{application.mother.firstName}} {{application.mother.lastName}}</p>
          <p class="mb-0">{{application.mother.idNumber}}</p>
          <div *ngIf="application.mother.supportingDocs">
            <p *ngFor="let document of application.mother.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>
        </div>
        <div class="mb-5" *ngIf="application.guardian && application.guardian.idNumber">
          <p class="mb-0"><strong>Guardian</strong></p>
          <p class="mb-0">{{application.guardian.firstName}} {{application.guardian.lastName}}</p>
          <p class="mb-0">{{application.guardian.idNumber}}</p>
          <div *ngIf="application.guardian.supportingDocs">
            <p *ngFor="let document of application.guardian.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>
        </div>
        <div class="d-grid gap-2" *ngIf="application.status!=='Withdrawn' && application.canWithdraw===true">
          <button class="btn btn-primary btn-lg mb-3" (click)="withdraw()" type="submit" *ngIf="application.status !== 'Rejected' && application.status !== 'ProvisionallyFunded' && application.status !== 'RegistrationReceived'">Cancel application</button>
        </div>
      </div>
    </div>


  </div>
</div>
