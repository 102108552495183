<div class="row">
  <div class="col-md-12">
    <app-card>
      <div class="row justify-content-center">
        <div class="col-md-10" *ngIf="!success">
          <ng-stepper #cdkStepper [linear]="true" class="">
            <cdk-step [stepControl]="" [optional]="false">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">1</div>
              <div class="step-title"></div> -->
              </ng-template>
              <!--              <app-apply-docs [cdkStepper]="cdkStepper"></app-apply-docs>-->
              <app-register-user [cdkStepper]="cdkStepper"></app-register-user>
            </cdk-step>

            <cdk-step [stepControl]="" [optional]="false">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">1</div>
              <div class="step-title"></div> -->
              </ng-template>
              <!--              <app-apply-docs [cdkStepper]="cdkStepper"></app-apply-docs>-->
              <app-apply-institution [cdkStepper]="cdkStepper"></app-apply-institution>
            </cdk-step>

            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">2</div>
              <div class="step-title"></div> -->
              </ng-template>
              <app-apply-study [cdkStepper]="cdkStepper"></app-apply-study>
            </cdk-step>


            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService.application.metadata.skipParentsScreen ===false">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">3</div>
              <div class="step-title"></div> -->
              </ng-template>
              <app-apply-details [cdkStepper]="cdkStepper"></app-apply-details>
            </cdk-step>

            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">4</div>
              <div class="step-title">Documents</div> -->
              </ng-template>
              <app-apply-docs *ngIf="cdkStepper.selectedIndex==5" [cdkStepper]="cdkStepper"></app-apply-docs>

            </cdk-step>
            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">5</div>
              <div class="step-title">Confirm</div> -->
              </ng-template>
              <app-apply-confirm [cdkStepper]="cdkStepper"></app-apply-confirm>
            </cdk-step>
            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">5</div>
              <div class="step-title">Terms</div> -->
              </ng-template>

              <form (ngSubmit)="onSubmit()" #termsCondition="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">

                <div *ngIf="item" [innerHTML]="item.content"></div>

                <div class="form-group text-left mb-5 text-start">
                  <div class="checkbox checkbox-fill d-inline">
                    <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2"  required [(ngModel)]="agree" name="agree"
                           required>
                    <label for="checkbox-fill-2" class="cr">I have read the full Terms and Conditions. <span
                      class="text-end"><a style="color: #000;" routerLink="/terms-conditions/SubmitApplication" target="_blank">View T's&C's</a></span></label>

                    <div class="invalid-feedback">
                      Please agree to the terms and conditions
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-grid gap-2">
                      <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="closeModal()">Decline</button>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <div class="d-grid gap-2">

                      <button class="btn btn-primary btn-lg mb-3" type="submit" >Accept</button>
                    </div>

                  </div>
                </div>
              </form>




            </cdk-step>

          </ng-stepper>
        </div>
        <div class="col-md-8 text-center mt-5" *ngIf="success">
          <div class="m-5">
            <img src="../../../assets/images/nsfas_logo.png" class="w-25">
          </div>
          <h2 class="mt-5">Application submitted successfully!</h2>
          <!-- <p class="mb-4">You have successfully submitted your application for NSFAS funding</p> -->

          <div class="card mx-auto w-75">
            <div class="card-body-thin">
              <div class="row">
                <div class="col-md-1 text-center">
                  <i class="fa fa-user fa-2x pt-2"></i>

                </div>
                <div class="col-md-7 text-start">
                  <p class="notif-body mb-0">{{applicationsService.application.user.firstName}} {{applicationsService.application.user.lastName}}</p>
                  <p class="mb-1 text-muted">
                    {{applicationsService.application.user.idNumber}}
                  </p>
                  <p class="mb-1 text-muted">
                    Ref:{{applicationsService.application.reference}}
                  </p>
                </div>
                <div class="col-md-4 text-end align-items-center align-middle">
                  <button class="btn btn-outline-primary btn-block" type="button" (click)="viewApplication()">View application</button>

                </div>
              </div>
            </div>
          </div>

          <div class="mx-5">
            <!--            <div class="alert alert-light"><small>Please note your application is {{applicationsService?.application?.status}}</small></div>-->

            <!-- <div class="d-grid gap-2"> -->

            <button class="btn btn-primary btn-block mb-5 w-30" type="button"
                    (click)="closeModal()">Dashboard</button>
            <!-- </div> -->
          </div>



        </div>

      </div>
    </app-card>
  </div>
</div>
