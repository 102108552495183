<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3 text-center">NSFAS funding application</h2>

    <form (ngSubmit)="onSubmit()" #kinForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">

  <h5 class="mb-4 text-primary"><strong>Institution information</strong></h5>

      <p>Are you disabled?</p>

      <div class="row">
        <div class="col-md-9">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="disabled" id="disabilityNo" [value]="false"
                   [(ngModel)]="applicationsService.application.disabled">
            <label class="cr" for="disabilityNo">No</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="disabled" id="disabilityYes" [value]="true"
                   [(ngModel)]="applicationsService.application.disabled">
            <label class="cr" for="disabilityYes">Yes</label>
          </div>
        </div>
      </div>


      <div class="alert alert-light text-start text-dark" *ngIf="applicationsService.application.disabled">
        <small>Please note, you will have to provide proof of disability

        </small>
        <a href="/assets/NSFAS%20Disability%20Annexure%20A_2023.pdf" target="_blank">Download annexure</a>
      </div>
<!--      <p>Are you currently studying?</p>-->
<!--      <div class="row">-->
<!--        <div class="col-md-9">-->
<!--          <div class="radio d-block mb-3">-->
<!--            <input class="form-check-input" type="radio" [disabled]="canEdit()" name="studying" id="studyingNo" [value]="false"-->
<!--              [(ngModel)]="applicationsService.application.currentlyStudying">-->
<!--            <label class="cr" for="studyingNo">No</label>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-3">-->
<!--          <div class="radio d-block mb-3">-->
<!--            <input class="form-check-input" type="radio" [disabled]="canEdit()" name="studying" id="studyingYes" [value]="true"-->
<!--              [(ngModel)]="applicationsService.application.currentlyStudying">-->
<!--            <label class="cr" for="studyingYes">Yes</label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->



     <div class="input-group mb-3">
        <select class="form-select"  required [(ngModel)]="applicationsService.application.highestGrade" name="highestGrade" appGrade></select>
        <div class="invalid-feedback">
          Highest academic level achieved is required
        </div>
      </div>
<!--      <p>Where are you {{applicationsService.application.currentlyStudying==true?'currently studying?':'going to study?'}}</p>-->
<!--      <div class="input-group mb-3">-->
<!--        <select class="form-select" [disabled]="canEdit()" multiple required [(ngModel)]="applicationsService.application.higherEducationInstitutionTypes" name="higherEducationInstitutionTypes" [checkOpened]="true" appHigherEducationInstitutionTypes (onSelect)="onSelect()"></select>-->
<!--        <div class="invalid-feedback">-->
<!--          Higher education institution type is required-->
<!--        </div>-->
<!--      </div>-->

       <p>For which year are you applying for?</p>
      <div class="input-group mb-3">
          <select class="form-select" [disabled]="canEdit()" required [(ngModel)]="applicationsService.application.year" name="year">
            <option [value]="year" *ngFor="let year of years">{{year}}</option>
          </select>

        <div class="invalid-feedback">
          Year you are applying for is required
        </div>
      </div>
<!--      <div class="input-group mb-3">-->
<!--        <select class="form-select" required [disabled]="canEdit()" [(ngModel)]="applicationsService.application.studyTerm" [checkOpened]="true"  [showAllT2]="true" name="studyTerm" appStudyTerm></select>-->
<!--        <div class="invalid-feedback">-->
<!--          Study term is required-->
<!--        </div>-->
<!--      </div>-->
      <!-- <div class="alert alert-light mb-3 text-start text-dark">
        <small>Please note, you will have to provide proof of disability</small>
      </div> -->
      <h5 class="mb-4 text-primary"><strong>Select 3 Institutions</strong></h5>
      <div class="input-group mb-3" *ngIf="canEdit() === false">
        <select class="form-select" required [(ngModel)]="applicationsService.application.selectedHigherEducationInstitutionOne" name="higherEducationInstitutionOne"  [application]="applicationsService.application" appHigherEducationInstitution></select>
        <div class="invalid-feedback">
          First Higher institution is required
        </div>
      </div>

      <div class="input-group mb-3" *ngIf="canEdit()">
        <select class="form-select" required [(ngModel)]="applicationsService.application.selectedHigherEducationInstitutionOne" name="higherEducationInstitutionOne" [application]="applicationsService.application" appHigherEducationInstitution></select>
        <div class="invalid-feedback">
          First Higher institution is required
        </div>
      </div>

      <div class="input-group mb-3" *ngIf="canEdit() === false">
        <select class="form-select"  [(ngModel)]="applicationsService.application.selectedHigherEducationInstitutionTwo" name="higherEducationInstitutionTwo" [application]="applicationsService.application" appHigherEducationInstitution></select>
        <div class="invalid-feedback">
          Second Higher institution is required
        </div>
      </div>

      <div class="input-group mb-3" *ngIf="canEdit()">
        <select class="form-select"  [(ngModel)]="applicationsService.application.selectedHigherEducationInstitutionTwo" name="higherEducationInstitutionTwo" [application]="applicationsService.application" appHigherEducationInstitution></select>
        <div class="invalid-feedback">
          Second Higher institution is required
        </div>
      </div>

      <div class="input-group mb-3" *ngIf="canEdit() === false">
        <select class="form-select" [(ngModel)]="applicationsService.application.selectedHigherEducationInstitutionThree" name="higherEducationInstitutionThree" [higherEducationInstitutionType]="applicationsService.application.higherEducationInstitutionType" [application]="applicationsService.application" appHigherEducationInstitution></select>
        <div class="invalid-feedback">
          Third Higher institution is required
        </div>
      </div>

      <div class="input-group mb-3" *ngIf="canEdit()">
        <select class="form-select" [(ngModel)]="applicationsService.application.selectedHigherEducationInstitutionThree" name="higherEducationInstitutionThree" [application]="applicationsService.application" appHigherEducationInstitution></select>
        <div class="invalid-feedback">
          Third Higher institution is required
        </div>
      </div>

      <h5 class="mb-4 text-primary"><strong>Study information</strong></h5>

      <div class="input-group mb-3">
        <select class="form-select" required [(ngModel)]="applicationsService.application.courseOne"  [application]="applicationsService.application" name="courseOne" appCourse></select>
        <div class="invalid-feedback">
          Field of study is required
        </div>

      </div>
      <div class="input-group mb-3">
        <select class="form-select" required [(ngModel)]="applicationsService.application.courseTwo"  [application]="applicationsService.application"  name="courseTwo" appCourse></select>
        <div class="invalid-feedback">
          Field of study is required
        </div>
      </div>
      <div class="input-group mb-3">
        <select class="form-select" required [(ngModel)]="applicationsService.application.courseThree"  [application]="applicationsService.application"  name="courseThree" appCourse></select>
        <div class="invalid-feedback">
          Field of study is required
        </div>
      </div>

      <!-- <h5 class="mb-4 text-primary"><strong>Demographics information</strong></h5> -->

      <!-- <div class="input-group mb-3">
        <input type="text" autocomplete="off" class="form-control" placeholder="Street number" name="address.line1" [(ngModel)]="applicationsService.application.address.line1">
        <div class="invalid-feedback">
          Street number is required
        </div>
      </div> -->
      <!-- <div class="input-group mb-3">
        <input type="text" autocomplete="off" class="form-control" placeholder="Street name" name="address.line2" [(ngModel)]="applicationsService.application.address.line2">
        <div class="invalid-feedback">
          Street name is required
        </div>
      </div> -->
      <!-- <div class="input-group mb-3">
        <input type="text" autocomplete="off" class="form-control" placeholder="Town" name="address.town" [(ngModel)]="applicationsService.application.address.town" required>
        <div class="invalid-feedback">
          Town is required
        </div>
      </div> -->
     <!-- <div class="input-group mb-3">
       <input type="text" autocomplete="off" class="form-control" placeholder="City" name="address.suburb" [(ngModel)]="applicationsService.application.address.suburb" required>
        <div class="invalid-feedback">
          City is required
       </div>
    </div> -->

      <!-- <div class="input-group mb-3">
        <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Postal code" name="address.postalCode" [(ngModel)]="applicationsService.application.address.postalCode" required>
        <div class="invalid-feedback">
          Invalid Postal code
        </div>
      </div> -->


      <!-- <div class="input-group mb-3">

          <select class="form-select" required [(ngModel)]="applicationsService.application.race" name="race" appRace>
          </select>
        <div class="invalid-feedback">
          Race is required
        </div>
      </div>
      <div class="input-group mb-3">

          <select class="form-select" required [(ngModel)]="applicationsService.application.placeOfApplication" name="placeOfApplication" [title]="'Where are you applying from?'" appPlaceOfApplication></select>
        <div class="invalid-feedback">
          Where are you applying from is required
        </div>
      </div>
      <div class="input-group mb-3">
          <select class="form-select" required [(ngModel)]="applicationsService.application.lastHighSchoolProvince" [title]="'Which province is your last high school located?'" name="lastHighSchoolProvince" appProvince (onSelect)="onProvinceSelect()"></select>
          <div class="invalid-feedback">
            Province of last high school currently located is required
          </div>
      </div> -->

<!--      <div class="input-group mb-3">-->

<!--        <select class="form-select" required [(ngModel)]="applicationsService.application.lastHighSchoolMunicipality" [province]="applicationsService.application.lastHighSchoolProvince" [title]="'Which municipality is your last high school located?'" name="lastHighSchoolMunicipality" appMunicipality (onSelect)="onMunicipalitySelect()"></select>-->
<!--        <div class="invalid-feedback">-->
<!--          Municipality of last high school currently located is required-->
<!--        </div>-->
<!--      </div>-->
      <!-- <div class="input-group mb-3">

        <select class="form-select" required [(ngModel)]="applicationsService.application.lastHighSchool" [province]="applicationsService.application.lastHighSchoolProvince"  [title]="'Name of last high school'" name="lastHighSchool" appSchool></select>
        <div class="invalid-feedback">
          Name of last high school is required
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-6">
          <div class="d-grid gap-2">

            <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
          </div>

        </div>
        <div class="col-md-6">
          <div class="d-grid gap-2">

            <button class="btn btn-primary btn-lg mb-3" type="submit" >Next</button>
          </div>

        </div>
      </div>
    </form>

  </div>

</div>
