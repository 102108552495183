import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Appeal} from './appeal';
import {Application, Documets, ErrorMessage, Spouse} from '../../applications/application/application';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {AppealsService} from '../../appeals/appeals.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {AppealDocumentsService} from '../../admin-config/appeal-documents/appeal-documents.service';
import {AppealDocument} from '../../admin-config/appeal-documents/appeal-document/appeal-document';
import {Observable, ObservableInput} from 'rxjs/Observable';
import {Attachment} from '../../core/models';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import { DocumentTypeEnum } from './document-type-enum';
import { Utils } from 'src/app/utils/utils';

declare var $: any;

@Component({
  selector: 'app-appeal',
  templateUrl: './appeal.component.html',
  styleUrls: ['./appeal.component.scss']
})
export class AppealComponent implements OnInit {
  success = false;
  requestAttachment = false;
  @Input() application: Application;
  appeals: Array<Appeal> = new Array<Appeal>();
  appealSupportingDocuments: Map<number, Array<AppealDocument>> = new Map<number, Array<AppealDocument>>();
  public validated: boolean;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('ngFormAppeal', {static: false})
  public ngFormAppeal: NgForm;
  documents: Documets = new Documets();
  errorMessage: ErrorMessage = new ErrorMessage();
  allErrorMessageForDownloads: Array<string>;

  constructor(public activeModal: NgbActiveModal, public appealServices: AppealsService,
              public appealDocumentsService: AppealDocumentsService, private router: Router,
              private matSnackBar: MatSnackBar, public modalService: NgbModal,
              protected gaService: GoogleAnalyticsService,
              public meta: Meta, public title: Title) {
    this.title.setTitle('Appeal');
  }


  public parents(appeal: Appeal) {
    if (appeal?.appealReason?.description?.indexOf('I am financially dependent on my parents') > -1) {
      return true;
    }
    return false;
  }

  public hasParent(appeal: Appeal, parentType: string): boolean {
    switch (parentType) {
      case "father": return this.hasFather(appeal);
      case "mother": return this.hasMother(appeal);
      case "guardian": return this.hasGuardian(appeal);
      default: return false;
    }
  }

  public isParentVerified(appeal: Appeal, parentType: string): boolean {
    switch (parentType) {
      case "father": return this.isFatherVerified(appeal);
      case "mother": return this.isMotherVerfied(appeal);
      case "guardian": return this.isGuardianVerified(appeal);
      default: return false;
    }
  }

  public isFinanciallyDependentOnParent(appeal: Appeal): boolean {
    return appeal?.appealReason?.description?.toLowerCase().includes('i am financially dependent on my parents');
  }

  private isFinanciallyDependentOnSpouse(appeal: Appeal): boolean {
    return appeal?.appealReason?.description.toLowerCase().includes("i am financially dependent on my spouse");
  }

  private parentsDeceased(appeal: Appeal): boolean {
    return appeal?.appealReason?.description.toLowerCase().includes("my parents are deceased");
  }

  private doesNotKnowParents(appeal: Appeal): boolean {
    return appeal?.appealReason?.description.toLowerCase().includes("i do not know my parents");
  }

  public isFinanciallyDepdentOnParentOrParentsDeceased(appeal: Appeal) {
    return this.isFinanciallyDependentOnParent(appeal) || this.parentsDeceased(appeal);
  }

  public hasFather(appeal: Appeal): boolean {
    return appeal?.application?.father !== null;
  }

  public isFatherVerified(appeal: Appeal): boolean {
    return this.hasFather(appeal) && appeal?.application?.father?.validated;
  }

  public hasMother(appeal: Appeal): boolean {
    return appeal?.application?.mother !== null;
  }

  public isMotherVerfied(appeal: Appeal): boolean {
    return this.hasMother(appeal) && appeal?.application?.mother?.validated;
  }

  public hasGuardian(appeal: Appeal): boolean {
    return appeal?.application?.guardian !== null;
  }

  public isGuardianVerified(appeal: Appeal): boolean {
    return this.hasGuardian(appeal) && appeal?.application?.guardian?.validated;
  }

  public atLeastOneCareGiverVerified(appeal: Appeal): boolean {
    return this.isFatherVerified(appeal) || this.isMotherVerfied(appeal) || this.isGuardianVerified(appeal);
  }

  trackByFn(index: any, item: any) {
    return index;
  }
  public spouse(appeal: Appeal) {

    if (appeal?.appealReason?.description?.toLowerCase().includes('i am financially dependent on my spouse')) {
      return true;
    }
    return false;
  }

  retrieveDocumentRequired(appeal: Appeal) {
    if (appeal.appealReason) {
      appeal.supportingDocuments = new Array<Attachment>();
      const that = this;
      this.blockUI.start();
      this.appealDocumentsService.getByAppealReason(appeal.appealReason).subscribe((appealSupportingDocuments: Array<AppealDocument>) => {
        this.addSupportingDocuments(appeal, appealSupportingDocuments, that);
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    }
  }

  public isParentOrSpouseSelectedForDocument(description: string, index: number): boolean {
    const parentType = this.getNameFromString(description);
    const appeal = this.appeals[index];
    if (this.isFinanciallyDependentOnParent(appeal) || this.parentsDeceased(appeal)) {
      return this.getDocumentsBySelectedType(appeal, parentType);
    }
    return true;
  }

  private getDocumentsBySelectedType(appeal: Appeal, parentType: string): boolean {
    switch (parentType.toLowerCase()) {
      case "father": return appeal.fatherDetails;
      case "mother": return appeal.motherDetails;
      case "guardian": return appeal.guardianDetails;
      default: return true;
    }
  }

  private addSupportingDocuments(appeal: Appeal, appealSupportingDocuments: Array<AppealDocument>, instance: any): void {
    if (this.isFinanciallyDependentOnParent(appeal) || this.parentsDeceased(appeal)) {
      this.addDocumentsForCareGivers(appeal, appealSupportingDocuments, instance);
      return;
    }

    if (this.isFinanciallyDependentOnSpouse(appeal)) {
      this.addDocumentsForSpouse(appeal, appealSupportingDocuments, instance);
      return;
    }

    this.appealSupportingDocuments.set(instance.appeals.indexOf(appeal), appealSupportingDocuments);
    appealSupportingDocuments.forEach(document => this.addAttachment(appeal, document));
  }

  private addDocumentsForSpouse(appeal: Appeal, appealSupportingDocument: Array<AppealDocument>, instance: any): void {
    const filterdDocuments = appealSupportingDocument.filter(appealDocument => this.isDocumentRequiredForSpouse(appeal, appealDocument));
    this.appealSupportingDocuments.set(instance.appeals.indexOf(appeal), filterdDocuments);
    instance.requestAttachment = filterdDocuments.length > 0;

    filterdDocuments.forEach(document => this.addAttachment(appeal, document))
  }

  private isDocumentRequiredForSpouse(appeal: Appeal, appealSupportDocument: AppealDocument): boolean {
    const isMarrigeCertificate = appealSupportDocument.description.toLowerCase() === "marriage certificate";
    if (isMarrigeCertificate && this.isSpouseVerified(appeal)) {
      return false;
    }
    return true;
  }

  private hasSpouse(appeal: Appeal): boolean {
    return appeal.application?.spouse !== null;
  }

  private isSpouseVerified(appeal: Appeal): boolean {
    return this.hasSpouse(appeal) && appeal.application?.spouse?.validated;
  }

  private addDocumentsForCareGivers(appeal: Appeal, appealSupportingDocument: Array<AppealDocument>, instance: any): void {
    const filterOnlyRequired = appealSupportingDocument                                  
                                  .filter(supportingDoc => this.requireDocumentForCareGiver(appeal, supportingDoc))
                                  .filter(supportingDoc => this.filterByApplicationByAge(supportingDoc, appeal));
    instance.requestAttachment = filterOnlyRequired.length > 0;
    this.appealSupportingDocuments.set(instance.appeals.indexOf(appeal), filterOnlyRequired);

    filterOnlyRequired.forEach((appealDocument: AppealDocument) => this.addAttachment(appeal, appealDocument));        
  }

  private filterByApplicationByAge(supportingDoc: AppealDocument, appeal: Appeal): boolean {
    if (!supportingDoc.description.toLowerCase().startsWith("nsfas declaration signed by")) {
      return true;
    }
    if (this.application.age === null || this.application.age === undefined) {
      return true;
    }
    return  this.application.age <= 34 &&
            !this.atLeastOneCareGiverVerified(appeal); 
  }

  private allCareGiversUnverified(appeal: Appeal): boolean {
    return !appeal.application?.father?.validated && !appeal.application?.mother?.validated && !appeal.application?.guardian?.validated;
  }

  private addAttachment(appeal: Appeal, appealDocument: AppealDocument): void {
    const attachement = new Attachment();
    attachement.caption = appealDocument.description;
    attachement.appealSupportingDocument = appealDocument;
    appeal.supportingDocuments.push(attachement);
  }

  private requireDocumentForCareGiver(appeal: Appeal, appealDocument: AppealDocument): boolean {    
    const parentType = this.getNameFromString(appealDocument.description).toLowerCase();

    if (this.searchEdgeCases(appealDocument, parentType)) {
      return true;
    }

    if (appealDocument.description.toLowerCase().includes("id")) {
      return !this.hasParent(appeal, parentType) || !this.isParentVerified(appeal, parentType);
    } else {
      const documentForType = appealDocument.description.toLowerCase();
      const parentTypesAccepted = ["father", "mother", "guardian"]
      return parentTypesAccepted.find(type => documentForType.includes(type)) !== undefined;
    }
  }

  private searchEdgeCases(appealDocument: AppealDocument, parentType: string): boolean {
    if (parentType === "applicant") {
      return true;
    }
    return appealDocument.description.toLowerCase().includes("nsfas consent form for applicant");
  }

  private getNameFromString(message: string): string {
    return message.substring(message.lastIndexOf(" "))?.toLowerCase()?.trim();
  }

  ngOnInit() {

    this.appeals = new Array<Appeal>();
    if (this.application.rejectReason) {
      const appeal = new Appeal();
      appeal.application = this.application;
      appeal.supportingDocuments = new Array<Attachment>();
      this.appeals.push(appeal);
    } else if (this.application.rejectReasons) {
      this.application.rejectReasons.forEach((rejectReason) => {
        const appeal = new Appeal();
        appeal.application = this.application;
        appeal.supportingDocuments = new Array<Attachment>();
        this.appeals.push(appeal);
      });
    }
    this.requestAttachment = false;
  }

  private clearRelationshipsIfIDNumberIsNull(appeal: Appeal): void {
    if (appeal && !appeal.guardian?.idNumber) {
      appeal.guardian = null;
    }
    if (appeal.spouse && !appeal.spouse?.idNumber) {
      appeal.spouse = null;
    }
    if (appeal.mother && !appeal.mother?.idNumber) {
      appeal.mother = null;
    }
    if (appeal.father && !appeal.father?.idNumber) {
      appeal.father = null;
    }
  }

  private addForSpecificPerson(appeal: Appeal, key: string, filesUplaoded: Array<Attachment>): void {
    if (appeal[key] === null || appeal[key] === undefined) {
      return null;
    }
    const documentsForPerson = filesUplaoded.filter(file => file.caption.toLowerCase().includes(key));
    appeal[key].supportingDocs = documentsForPerson;
  }

  private addSupportingDocumentsForSupportingPeople(appeal: Appeal, filesUploaded: Array<Attachment>) {
    const allSupportingPeople = ["mother", "father", "guardian", "spouse"];

    allSupportingPeople.forEach(person => this.addForSpecificPerson(appeal, person, filesUploaded));
  }

  save(appeal: Appeal, filesUplaoded: Array<Attachment>) {
    this.blockUI.start();
    this.clearRelationshipsIfIDNumberIsNull(appeal);    
    this.addSupportingDocumentsForSupportingPeople(appeal, filesUplaoded);
    appeal.supportingDocuments = filesUplaoded;
    this.appealServices.save(appeal).subscribe((appealA: Appeal) => {
      appeal = appealA;
      this.blockUI.stop();
      this.success = true;
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 400) {
        this.documents = JSON.parse(JSON.stringify(error.error));
        this.errorMessage = JSON.parse(JSON.stringify(error.error));
        if (this.errorMessage.code || this.errorMessage.message) {
          this.matSnackBar.open(this.errorMessage.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else {
          if (this.documents.mother) {
            appeal.mother = this.documents.mother;
          }
          if (this.documents.father) {
            appeal.father = this.documents.father;
          }
          if (this.documents.guardian) {
            appeal.guardian = this.documents.guardian;
          }
          if (this.documents.spouse) {
            appeal.spouse = this.documents.spouse;
          }
        }
      } else if (error.status === 401) {
        this.router.navigate(['/']);
        this.showMatSnackBar("Your session has expired");
      } else {
        this.showMatSnackBar(error.error.message);        
      }
    });

  }

  checkForVerifiedParents(appeal: Appeal): boolean {
    return false;
  }

  nsfasConsentFormForParentDocumentUploaded(uploadedDocuments: Array<Attachment>): boolean {
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.NSFAS_CONSENT_FORM_FOR_PARENT_GUARDIAN);
  }

  nsfasConsentFormForApplicantDocumentUploaded(uploadedDocuments: Array<Attachment>): boolean {
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.NSFAS_CONSENT_FORM_FOR_APPLICANT);
  }

  idCopyOfParentDocumentUploaded(uploadedDocuments: Array<Attachment>): boolean {
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.ID_COPY);
  }

  nsfasDeclerationSignedByParentDocumentUploaded(uploadedDocuments: Array<Attachment>): boolean {
    if (this.application.age > 34) {
      return true;
    }
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.NSFAS_DECLARATION_SIGNED_BY_PARENT_GUARDIAN);
  }

  allRequiredDocumentsForVerifiedParentsUploaded(uploadedDocument: Array<Attachment>): boolean {
    return this.nsfasConsentFormForApplicantDocumentUploaded(uploadedDocument) &&
            this.nsfasConsentFormForParentDocumentUploaded(uploadedDocument);
  }

  allRequiredDocumentsForUnverifiedParentsUploaded(uploadedDocument: Array<Attachment>): boolean {
    return this.allRequiredDocumentsForVerifiedParentsUploaded(uploadedDocument) &&
            this.idCopyOfParentDocumentUploaded(uploadedDocument) &&
            this.nsfasDeclerationSignedByParentDocumentUploaded(uploadedDocument);
  }

  findDocument(uploadedDocument: Array<Attachment>, documentType: DocumentTypeEnum) {
    const isDocumentUploaded = uploadedDocument.find(document => document.documentType.toLowerCase() === documentType.toLowerCase()) !== undefined
    if (!isDocumentUploaded) {
      this.allErrorMessageForDownloads.push(this.getUserFriendlyDocumentName(documentType));
    }
    return isDocumentUploaded;
  }

  allRequiredDocumentsForFinanciallyDependentOnParentUploaded(appeal: Appeal, uploadedDocuments: Array<Attachment>): boolean {
    if (this.atLeastOneCareGiverVerified(appeal)) {
      return this.allRequiredDocumentsForVerifiedParentsUploaded(uploadedDocuments);
    } 
    return this.allRequiredDocumentsForUnverifiedParentsUploaded(uploadedDocuments);
  }

  getAllFilesUploadedForAppeal(appeal: Appeal): Array<Attachment> {
    if (appeal.supportingDocuments === null){
      return [];
    }
    const allFilesUploaded = [];
    appeal.supportingDocuments.forEach(document => {
      if (document.file !== null && this.isSupportedFile(document.file)) {
        document.documentType = this.getDcumentTypeFromCaption(document).toUpperCase();
        allFilesUploaded.push(document);
      }
    });
    return allFilesUploaded;    
  }

  private getDcumentTypeFromCaption(document: Attachment): string {
    const safeCaption = Utils.replaceAll(document.caption, "  ", " ");
    if (this.isConsentFormForParents(safeCaption)) {
      return DocumentTypeEnum.NSFAS_CONSENT_FORM_FOR_PARENT_GUARDIAN;
    }
    if (this.isIDCopyForParentOrSpouse(safeCaption)) {
      return DocumentTypeEnum.ID_COPY;
    }
    if (this.isDeclarationSignedByParents(safeCaption)) {
      return DocumentTypeEnum.NSFAS_DECLARATION_SIGNED_BY_PARENT_GUARDIAN;
    }
    if (this.isDeathCertificate(safeCaption)) {
      return DocumentTypeEnum.DEATH_CERTIFICATE_OF_PARENT_GUARDIAN;
    }
    if (this.isConsentForApplicant(safeCaption)) {
      return DocumentTypeEnum.NSFAS_CONSENT_FORM_FOR_APPLICANT;
    }
    if (safeCaption.toLowerCase().includes("vulnerable child form")) {
      return DocumentTypeEnum.NSFAS_DECLARATION_FORM_OR_VULNERABLE_CHILD_FORM;
    }
    if (safeCaption.toLowerCase() === "nsfas consent form for spouse") {
      return DocumentTypeEnum.NSFAS_CONSENT_FORM_OF_SPOUSE
    }
    if (safeCaption.toLowerCase() === "marriage certificate") {
      return DocumentTypeEnum.MARRIAGE_CERTIFICATE;
    }
    return DocumentTypeEnum.OTHER;
  }

  private isConsentForApplicant(caption: string): boolean {
    return caption.toLowerCase() === "consent form for applicant" || caption.toLowerCase() === "nsfas consent form for applicant"
  }

  private isConsentFormForParents(caption: string): boolean {
    const parentsCaptions = ["nsfas consent form for father", "nsfas consent form for mother", "nsfas consent form for guardian"];
    return parentsCaptions.includes(caption.toLowerCase());
  }

  private isIDCopyForParentOrSpouse(caption: string): boolean {
    return caption.toLowerCase().startsWith("id copy of");
  }

  private isDeclarationSignedByParents(caption: string): boolean {
    return caption.toLowerCase().startsWith("nsfas declaration signed by");
  }

  private isDeathCertificate(caption: string): boolean {
    return caption.toLowerCase().startsWith("death certificate of");
  }


  private isSupportedFile(file: File): boolean {
    return file instanceof Blob;
  }

  private allRequiredDocumentsForVerifiedSpouseUploaded(uploadedDocuments: Array<Attachment>): boolean {
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.NSFAS_CONSENT_FORM_OF_SPOUSE) &&
            this.nsfasConsentFormForApplicantDocumentUploaded(uploadedDocuments)
  }

  private allRequiredDocumentsForUnverifiedSpouseUploaded(uploadedDocuments: Array<Attachment>): boolean {
    return this.allRequiredDocumentsForVerifiedSpouseUploaded(uploadedDocuments) &&
            this.findDocument(uploadedDocuments, DocumentTypeEnum.MARRIAGE_CERTIFICATE);
  }

  private allRequiredDocumentsForSpouseUploaded(appeal: Appeal, uploadedDocuments: Array<Attachment>): boolean {
    if (this.isSpouseVerified(appeal)) {
      return this.allRequiredDocumentsForVerifiedSpouseUploaded(uploadedDocuments);
    }
    return this.allRequiredDocumentsForUnverifiedSpouseUploaded(uploadedDocuments);
  }

  private allRequiredDocumentsForParentsDeceased(uploadedDocuments: Array<Attachment>): boolean {
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.DEATH_CERTIFICATE_OF_PARENT_GUARDIAN) &&
            this.nsfasConsentFormForApplicantDocumentUploaded(uploadedDocuments);
  }

  private allRequiredDocumentsForIDoNotKnowMyParents(uploadedDocuments: Array<Attachment>): boolean {
    return this.findDocument(uploadedDocuments, DocumentTypeEnum.NSFAS_DECLARATION_FORM_OR_VULNERABLE_CHILD_FORM) &&
            this.nsfasConsentFormForApplicantDocumentUploaded(uploadedDocuments);
  }

  private validateAllDocumentsUploaded(appeal: Appeal, uploadedDocuments: Array<Attachment>): boolean {
    this.allErrorMessageForDownloads = [];
    if (this.isFinanciallyDependentOnParent(appeal)) {
      return this.allRequiredDocumentsForFinanciallyDependentOnParentUploaded(appeal, uploadedDocuments);
    }
    if (this.isFinanciallyDependentOnSpouse(appeal)) {
      return this.allRequiredDocumentsForSpouseUploaded(appeal, uploadedDocuments);
    }
    if (this.parentsDeceased(appeal)) {
      return this.allRequiredDocumentsForParentsDeceased(uploadedDocuments);
    }
    if (this.doesNotKnowParents(appeal)) {
      return this.allRequiredDocumentsForIDoNotKnowMyParents(uploadedDocuments);
    }
    return true;
  }

  private isNotSelected(value: any) {    
    return value === undefined || value === false;
  }

  private atLeastOneParentDetailedPopulated(appeal: Appeal): boolean {
    const atLeastOnePopulated = !this.isNotSelected(appeal.fatherDetails) || !this.isNotSelected(appeal.motherDetails) || !this.isNotSelected(appeal.guardianDetails);
    if (!atLeastOnePopulated) {
      this.showMatSnackBar("Please provide your Mother, Father or Guardians details");
    }
    return atLeastOnePopulated;
  }

  private requriedFieldsPopulatedForSpouse(appeal: Appeal): boolean {
    const isSpouseTypeSelected = appeal.saSpouse !== undefined;
    if (!isSpouseTypeSelected) {
      this.showMatSnackBar("Please specify if spouse has South African ID number");
    }
    return isSpouseTypeSelected;
  }

  private allRequiredFieldsPopulated(appeal: Appeal): boolean {
    if (this.isFinanciallyDependentOnParent(appeal) || this.parentsDeceased(appeal)) {
      return this.atLeastOneParentDetailedPopulated(appeal);
    }
    if (this.isFinanciallyDependentOnSpouse(appeal)) {      
      return this.requriedFieldsPopulatedForSpouse(appeal);
    }
    return true;
  }

  showMatSnackBar(message: string) {
    this.matSnackBar.open(message, 'Okay', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  onSubmit() {
    if (!this.ngFormAppeal.valid) {
      this.validated = true;
      return;
    } else {
      this.appeals.forEach((appeal: Appeal)=>{

        const allFilesUploaded = this.getAllFilesUploadedForAppeal(appeal);
        
        if (!this.validateAllDocumentsUploaded(appeal, allFilesUploaded)) {
          const message = "Please upload the document " + this.allErrorMessageForDownloads.join(" ");
          this.showMatSnackBar(message);
          return;
        }

        if (!this.allRequiredFieldsPopulated(appeal)) {
          // Toast/Snack message to inform the user is sent from the failing method.
          return;
        }

        const requests = new Array<ObservableInput<Attachment>>();
        allFilesUploaded.forEach(file => {
          requests.push(this.appealServices.upload(file.file));
        })

        Observable.forkJoin(requests).subscribe((attachments: Array<Attachment>) => {
          attachments.forEach(attachment => {
            const foundAttachment = allFilesUploaded.find(file => file.name === attachment.name && file.content !== null);
            if (foundAttachment !== undefined) {
              foundAttachment.id = attachment.id;
              foundAttachment.created = attachment.created;
              foundAttachment.content = null;
            } else {
              throw Error("Attached documents cannot have the same name.");
            }
          });          
          this.save(appeal, allFilesUploaded);
        }, error => {
          console.error(`Unable to upload supporting documents ${error?.message}`);
          this.showMatSnackBar(`Unable to upload supporting documents: ${error?.message}`);
        });
      });
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  viewApplications() {
    this.activeModal.close();
    $('#openTrack').click();
  }

  clearMother(appeal: Appeal) {
    appeal.saMother = false;
    appeal.mother = new Spouse();
  }

  clearFather(appeal: Appeal) {
    appeal.saFather = false;
    appeal.father = new Spouse();
  }

  clearGuardian(appeal: Appeal) {
    appeal.saGuardian = false;
    appeal.guardian = new Spouse();
  }

  clear(appeal: Appeal) {
    appeal.fatherDetails = null;
    appeal.saFather = null;
    appeal.father = new Spouse();
    appeal.motherDetails = null;
    appeal.saMother = null;
    appeal.mother = new Spouse();
    appeal.guardianDetails = null;
    appeal.saGuardian = null;
    appeal.guardian = new Spouse();
  }

  getUserFriendlyDocumentName(documentType: DocumentTypeEnum): string {
    switch (documentType) {
      case DocumentTypeEnum.NSFAS_CONSENT_FORM_FOR_PARENT_GUARDIAN: return "NSFAS consent form for Parent/Guardian";
      case DocumentTypeEnum.NSFAS_CONSENT_FORM_FOR_APPLICANT: return "NSFAS consent form for applicant";
      case DocumentTypeEnum.ID_COPY: return "ID Copy";
      case DocumentTypeEnum.NSFAS_DECLARATION_SIGNED_BY_PARENT_GUARDIAN: return "NSFAS declaration signed by Parent/Guardian";
      case DocumentTypeEnum.NSFAS_CONSENT_FORM_OF_SPOUSE: return "NSFAS consent form of Spouse";
      case DocumentTypeEnum.MARRIAGE_CERTIFICATE: return "Marriage Certificate";
      case DocumentTypeEnum.DEATH_CERTIFICATE_OF_PARENT_GUARDIAN: return "Death Certificate of Parent/Guardian";
      case DocumentTypeEnum.NSFAS_DECLARATION_FORM_OR_VULNERABLE_CHILD_FORM: return "Vulnerable Child Form/NSFAS declaration form";
      default: return "Unknown document";
    }
  }
}
