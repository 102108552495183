import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CdkStepper} from '@angular/cdk/stepper';
import {NgForm} from '@angular/forms';
import {ApplicationsService} from '../../applications/applications.service';
import {Meta, Title} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Documets, ErrorMessage, Spouse} from '../../applications/application/application';
import {Router} from '@angular/router';
import {Attachment} from '../../core/models';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

declare var $: any;

@Component({
  selector: 'app-apply-docs',
  templateUrl: './apply-docs.component.html',
  styleUrls: ['./apply-docs.component.scss']
})
export class ApplyDocsComponent implements OnInit {
  public validated: boolean;
  @ViewChild('documentForm', {static: false})
  public documentForm: NgForm;
  @Input() cdkStepper: CdkStepper;
  documents: Documets = new Documets();
  errorMessage: ErrorMessage = new ErrorMessage();

  constructor(public activeModal: NgbActiveModal, protected assistedService: AssistedService,
              public applicationsService: ApplicationsService, private router: Router,
              protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar, public meta: Meta, public title: Title) {
    this.title.setTitle('Apply Docs');
  }

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit() {


    if(this.assistedService.idNumber) {
      this.blockUI.start();
      this.assistedService.validate().subscribe(() => {
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 400) {
          this.documents = JSON.parse(JSON.stringify(error.error));
          this.errorMessage = JSON.parse(JSON.stringify(error.error));
          if(this.errorMessage.code || this.errorMessage.message) {
            this.matSnackBar.open(this.errorMessage.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            this.cdkStepper.previous();
          } else {
            this.applicationsService.application.mother = new Spouse();
            this.applicationsService.application.father = new Spouse();
            this.applicationsService.application.guardian = new Spouse();
            this.applicationsService.application.spouse = new Spouse();
            this.applicationsService.application.vulnerableChildOrNonSassDeclaration = null;
            this.applicationsService.application.disabilityAnnexure = null;
            if (this.documents.mother) {
              this.applicationsService.application.mother = this.documents.mother;
            }
            if (this.documents.father) {
              this.applicationsService.application.father = this.documents.father;
            }
            if (this.documents.guardian) {
              this.applicationsService.application.guardian = this.documents.guardian;
            }
            if (this.documents.spouse) {
              this.applicationsService.application.spouse = this.documents.spouse;
            }
            if (this.documents.spouse) {
              this.applicationsService.application.spouse = this.documents.spouse;
            }

            if (this.documents.requiredDocuments) {
              this.documents.requiredDocuments.forEach((attachment: Attachment)=>{
                  if(attachment.documentType ==='VULNERABLE_CHILD_OR_NON_SASSA_DECLARATION') {
                    this.applicationsService.application.vulnerableChildOrNonSassDeclaration = attachment;
                  }else if(attachment.documentType ==='DISABILITY_ANNEXURE') {
                    this.applicationsService.application.disabilityAnnexure = attachment;
                  }
              })
            }
          }
        } else if (error.status === 401) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else {
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }else {
      this.blockUI.start();
      this.applicationsService.validate().subscribe(() => {
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 400) {
          this.documents = JSON.parse(JSON.stringify(error.error));
          this.errorMessage = JSON.parse(JSON.stringify(error.error));
          if(this.errorMessage.code || this.errorMessage.message) {
            this.matSnackBar.open(this.errorMessage.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            this.cdkStepper.previous();
          } else {
            this.applicationsService.application.mother = new Spouse();
            this.applicationsService.application.father = new Spouse();
            this.applicationsService.application.guardian = new Spouse();
            this.applicationsService.application.spouse = new Spouse();
            this.applicationsService.application.vulnerableChildOrNonSassDeclaration = null;
            this.applicationsService.application.disabilityAnnexure = null;
            if (this.documents.mother) {
              this.applicationsService.application.mother = this.documents.mother;
            }
            if (this.documents.father) {
              this.applicationsService.application.father = this.documents.father;
            }
            if (this.documents.guardian) {
              this.applicationsService.application.guardian = this.documents.guardian;
            }
            if (this.documents.spouse) {
              this.applicationsService.application.spouse = this.documents.spouse;
            }
            if (this.documents.requiredDocuments) {
              this.documents.requiredDocuments.forEach((attachment: Attachment)=>{
                if(attachment.documentType ==='VULNERABLE_CHILD_OR_NON_SASSA_DECLARATION') {
                }else if(attachment.documentType ==='DISABILITY_ANNEXURE') {
                  this.applicationsService.application.disabilityAnnexure = attachment;
                }
              })
            }
          }
        } else if (error.status === 401) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else {
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }

  }

  previous() {

    this.applicationsService.application.mother = new Spouse();
    this.applicationsService.application.father = new Spouse();
    this.applicationsService.application.guardian = new Spouse();
    this.applicationsService.application.spouse = new Spouse();
    this.applicationsService.application.vulnerableChildOrNonSassDeclaration = null;
    this.applicationsService.application.disabilityAnnexure = null;
    this.cdkStepper.previous();
  }

  onSubmit() {

    const that= this;
    if (!this.documentForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      let valid = true;
      if (this.applicationsService.application.disabilityAnnexure && !this.applicationsService.application.disabilityAnnexure.file) {
          valid = false;
          that.matSnackBar.open('Disability Annexure is required', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
      }
      if (this.applicationsService.application.mother && this.applicationsService.application.spouse.requiredDocuments) {
        this.applicationsService.application.mother.requiredDocuments.forEach((attachment: Attachment)=>{
          if(!attachment.file) {
            valid = false;
            that.matSnackBar.open(attachment.caption+' is required', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        })
      }
      if (this.applicationsService.application.father && this.applicationsService.application.father.requiredDocuments) {
        this.applicationsService.application.father.requiredDocuments.forEach((attachment: Attachment)=>{
          if(!attachment.file) {
            valid = false;
            that.matSnackBar.open(attachment.caption+' is required', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        })
      }
      if (this.applicationsService.application.guardian && this.applicationsService.application.guardian.requiredDocuments) {
        this.applicationsService.application.guardian.requiredDocuments.forEach((attachment: Attachment)=>{
          if(!attachment.file) {
            valid = false;
            that.matSnackBar.open(attachment.caption+' is required', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        })
      }
      if (this.applicationsService.application.spouse && this.applicationsService.application.spouse.requiredDocuments) {
        this.applicationsService.application.spouse.requiredDocuments.forEach((attachment: Attachment)=>{
          if(!attachment.file) {
            valid = false;
            that.matSnackBar.open(attachment.caption+' is required', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        })
      }
      if(valid === true) {
        this.cdkStepper.next();
      }
    }
  }

}
