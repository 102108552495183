import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../users/users.service';
import {ApplyStep} from 'src/app/core/models/apply';
import {ApplicationsService} from '../../applications/applications.service';
import {NgForm} from '@angular/forms';
import {CdkStepper} from '@angular/cdk/stepper';
import {User} from '../../admin/users/user/user';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Application, Documets} from '../../applications/application/application';
import {HigherEducationInstitutionTypesService} from '../../higher-education-institution-types/higher-education-institution-types.service';
declare var $: any;
@Component({
  selector: 'app-apply-institution',
  templateUrl: './apply-institution.component.html',
  styleUrls: ['./apply-institution.component.scss']
})
export class ApplyInstitutionComponent implements OnInit {
  public apply: ApplyStep;
  @Input() kinType: string;
  public validated: boolean;
  @ViewChild('kinForm', {static: false})
  public kinForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  @Input() cdkStepper: CdkStepper;
  years : Array<number>;
  documets: Documets = new Documets();
  constructor(public activeModal: NgbActiveModal, protected service: UsersService,protected gaService: GoogleAnalyticsService,
              public meta: Meta, public title: Title, protected assistedService: AssistedService,
              public cookieService: CookieService,private router: Router,
              protected higherEducationInstitutionTypesService: HigherEducationInstitutionTypesService,
              public applicationsService: ApplicationsService, public usersService: UsersService, private matSnackBar: MatSnackBar) {
    this.apply = new ApplyStep();
    this.title.setTitle('Apply Institution');
    this.activeModal.close();
  }

  ngOnInit() {
    this.higherEducationInstitutionTypesService.searchOpenedYear().subscribe((years : Array<number>)=>{
      this.years = years;
    });
  }

  onSelect() {
    this.applicationsService.application.higherEducationInstitution = null;
  }

  onProvinceSelect() {
    this.applicationsService.application.lastHighSchoolMunicipality = null;
    this.applicationsService.application.lastHighSchool = null;
  }

  onMunicipalitySelect() {
    this.applicationsService.application.lastHighSchool = null;
  }

  previous() {
    this.cdkStepper.previous();
  }
  closeModal(){
    this.activeModal.close();
  }
  normal() {
    this.blockUI.start();
    this.applicationsService.save().subscribe((application: Application)=>{
      this.blockUI.stop();
      this.applicationsService.application = application;
       this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.applicationsService.application.metadata = this.documets.metadata;
        this.cdkStepper.next();
      } else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

    });
  }

  dataCapture() {
    this.blockUI.start();
    this.assistedService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if(error.status === 400) {
        this.cdkStepper.next();
      }if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

    });
  }
  onSubmit() {


    if (!this.kinForm.valid) {
      this.validated = true;
      for (let controlsKey in this.kinForm.controls) {
        if (this.kinForm.controls[controlsKey].invalid) {
        }

      }
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      if(this.assistedService.idNumber){
        this.dataCapture();
      }else {
        this.normal();
      }


    }
  }

  canEdit() {
    if(this.applicationsService?.application?.reference?.indexOf('MCS')>-1) {
      return true;
    }
    return false;

  }

}
