<div class="row">
  <div class="col-md-11 offset-1">
    <img src="/assets/images/nsfas_logo.png" class="mt-3 mb-5" style="height: 77px;">
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <h2 class="mb-5 text-center">Security Questions</h2>
        <div class="alert alert-light text-start mb-3 text-dark">
          <small>Complete your security questions by filling in the relevant answers that only you know</small>
        </div>
        <div class="mx-5">
          <p>Fill in the answers to your secure questions</p>
          <form (ngSubmit)="onSubmit()" #userQuestions="ngForm" [ngClass]="validated?'was-validated':''">

            <div class="input-group mb-3" *ngFor="let userQuestion of questionsService.userQuestions; let i = index">
              <input type="text" class="form-control" [placeholder]="userQuestion.securityQuestion.question" [(ngModel)]="userQuestion.answer" [name]="'answer'+i" required>
              <div class="invalid-feedback">
                Answer to {{userQuestion.securityQuestion.question}} is required
              </div>
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-primary btn-block mb-5" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
