import {Race} from '../../admin-config/races/race/race';

export class Register {
  cellphone: string;
  email: string;
  firstName: string;
  id: string;
  idNumber: string;
  lastName: string
  password: string;
  agree: boolean;
  passwordCheck: string;
  race: Race;

}
