<div class="row">
  <div class="col-md-12">
    <app-card [showSave]="true">
      <div class="row justify-content-center">


        <div class="col-md-10" >
          <ng-stepper #cdkStepper [linear]="true" class="">
            <cdk-step [stepControl]="" [optional]="false">
              <ng-template cdkStepLabel>

              </ng-template>

              <app-apply-institution [cdkStepper]="cdkStepper"></app-apply-institution>
            </cdk-step>

            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>

              </ng-template>
              <app-apply-study [cdkStepper]="cdkStepper"></app-apply-study>
            </cdk-step>

<!--            <cdk-step [stepControl]="" [optional]="true">-->
<!--              <ng-template cdkStepLabel>-->
<!--                &lt;!&ndash; <div class="step-bullet">3</div>-->
<!--              <div class="step-title"></div> &ndash;&gt;-->
<!--              </ng-template>-->
<!--              <app-apply-laptop [cdkStepper]="cdkStepper"></app-apply-laptop>-->
<!--            </cdk-step>-->

            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService.application && ((applicationsService.application.metadata && applicationsService?.application?.metadata?.skipParentsScreen ===false) || !applicationsService?.application?.metadata) ">
              <ng-template cdkStepLabel>
              </ng-template>
              <app-apply-details [cdkStepper]="cdkStepper"></app-apply-details>
            </cdk-step>

            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService?.application && ((cdkStepper.selectedIndex==3 && applicationsService?.application?.metadata?.skipParentsScreen ===false) || (cdkStepper.selectedIndex==2 && applicationsService?.application?.metadata?.skipParentsScreen ===true))">
              <ng-template cdkStepLabel>
              </ng-template>
              <app-apply-upload-documents [cdkStepper]="cdkStepper"></app-apply-upload-documents>
            </cdk-step>

<!--            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService.application && applicationsService.application.metadata && applicationsService.application.metadata.skipParentsScreen ===false">-->
<!--              <ng-template cdkStepLabel>-->

<!--              </ng-template>-->
<!--              <app-apply-docs *ngIf="cdkStepper.selectedIndex==4" [cdkStepper]="cdkStepper"></app-apply-docs>-->
<!--            </cdk-step>-->

            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>

              </ng-template>
              <app-apply-confirm [cdkStepper]="cdkStepper"></app-apply-confirm>
            </cdk-step>
            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">5</div>
              <div class="step-title">Terms</div> -->
              </ng-template>

              <app-apply-terms [cdkStepper]="cdkStepper"></app-apply-terms>

            </cdk-step>

            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">5</div>
              <div class="step-title">Terms</div> -->
              </ng-template>
              <div class="col-md-8 text-center mt-5" >
                <div class="m-5">
                  <img src="../../../assets/images/nsfas_logo.png" class="w-25">
                </div>
                <h2 class="mt-5">Application submitted successfully!</h2>
                <!-- <p class="mb-4">You have successfully submitted your application for NSFAS funding</p> -->

                <div class="card mx-auto w-75">
                  <div class="card-body-thin">
                    <div class="row">
                      <div class="col-md-1 text-center">
                        <i class="fa fa-user fa-2x pt-2"></i>

                      </div>
                      <div class="col-md-7 text-start">
                        <p class="notif-body mb-0">{{applicationsService.application.user.firstName}} {{applicationsService.application.user.lastName}}</p>
                        <p class="mb-1 text-muted">
                          {{applicationsService.application.user.idNumber}}
                        </p>
                        <p class="mb-1 text-muted">
                          Ref:{{applicationsService.application.reference}}
                        </p>
                      </div>
                      <div class="col-md-4 text-end align-items-center align-middle">
                        <button class="btn btn-outline-primary btn-block" type="button" (click)="viewApplication()">View application</button>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="mx-5">
                  <!--            <div class="alert alert-light"><small>Please note your application is {{applicationsService?.application?.status}}</small></div>-->

                  <!-- <div class="d-grid gap-2"> -->

                  <button class="btn btn-primary btn-block mb-5 w-30" type="button"
                          (click)="closeModal()">Dashboard</button>
                  <!-- </div> -->
                </div>



              </div>
            </cdk-step>

          </ng-stepper>

        </div>

      </div>
    </app-card>
  </div>
</div>
